import React, { Component, Fragment } from "react";
import { withStyles, Grid, Button, InputAdornment, TextField, Box, Typography, Dialog, DialogContent, DialogActions, Slide, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Candidate from "./Candidate";
import Loader from "../Utilities/Loader/Loader";
import ErrorText from "../Utilities/Error/ErrorText";
import ReactPaginate from "react-paginate";
import oauthConfig from "../../oauthConfig";
import EmployerCandidateFilter from "../Filters/EmployerCandidateFilter/EmployerCandidateFilter";
import axios from "axios";
import queryString from "query-string";
import parse from "html-react-parser";

const styles = (theme) => ({
  search: {
    background: "#ffffff",
  },
  location: {
    background: "#ffffff",
    padding: "10px 8px",
    "&::placeholder": {
      fontSize: "0.8125rem",
    },
    "&:-ms-input-placeholder": {
      fontSize: "0.8125rem",
    },
    "&::-webkit-input-placeholder": {
      fontSize: "0.8125rem",
    },
  },
  middleSelect: {
    padding: "0 5px",
  },
  pagination: {
    display: "flex",
    listStyle: "none",
    justifyContent: "center",
    alignItems: "center",
    "& li": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 5px",
      width: "30px",
      height: "30px",
      cursor: "pointer",
    },
    "& a": {
      height: "20px",
      "&:focus": {
        outline: "0",
      },
    },
  },
  active: {
    background: "#eeeeee",
    borderRadius: "50%",
  },
  close: {
    position: "absolute",
    top: 0,
    right: 0,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Values is for values of query parameters check componentDidMount
let values;
// Values are for pager settings
let totalCandidates = 0;
let pageCount = null;
const perPage = 50;

//Immediate response of button status
let gainedCandidates = [];
let requestedCandidates = [];
//Just for Dialog profile button
let temporaryUuid;

class Candidates extends Component {
  state = {
    search: "",
    candidates: null,
    pageCount: null,
    page: 0,
    totalCandidates: 0,
    loading: false,
    invites: 0,
    dialog: {
      openDialog: false,
      dialogMsg: "",
      error: false,
      subscribe: false,
    },
    loadingContact: {
      loading: true,
      buttonID: null,
    },
    query: null,
    predefinedVals: null,
  };
  componentDidUpdate(prevProps) {
    if (this.props.currentOrg.nid !== prevProps.currentOrg.nid) {
      this.handleSearch("&search=+", 0);
    }
  }

  componentDidMount() {
    const saved = localStorage.getItem("candidateFilterQuery");
    if (saved) {
      values = { ...queryString.parse(saved) };
    } else {
      values = { ...queryString.parse(this.props.location.search) };
    }

    const page = parseInt(values.page);
    const isSeatch = values.hasOwnProperty("q");
    const arrSearchQuery = [];
    for (const prop in values) {
      if (prop !== "page" && prop !== "q") {
        arrSearchQuery.push(`&${prop}=${values[prop].split(" ").join("+")}`);
      }
      if (prop === "search") {
        const search = values[prop];
        this.setState({ search });
      }
    }
    const seacrhString = arrSearchQuery.join("");

    this.getInvites();
    this.setState({ page });

    if (isSeatch) {
      if (page > 0) {
        this.handleSearch(seacrhString, page);
      } else {
        this.handleSearch(seacrhString, 0);
      }
    } else {
      this.handleSearch("&search=+", 0);
      // console.log(arrSearchQuery)
    }
  }

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.handleSearch(`&search=${this.state.search.replace(/\s+/g, "+")}`, 0);
    }
  };

  handleReset = () => {
    localStorage.removeItem("candidateFilterQuery");
    this.handleSearch("", 0, "isAllFilter");
    // this.props.history.push({
    //     pathname: '/candidates/filter?&search=+',
    // })
  };

  handleClose = () => {
    this.setState({
      dialog: {
        openDialog: false,
        dialogMsg: "",
        error: false,
        subscribe: false,
      },
    });
  };

  handleChange = (e) => {
    this.setState({ search: e.target.value });
  };

  handlePageClick = (e) => {
    let query = this.state.query;
    this.handleSearch(query, e.selected);
  };

  handleContact = (candidateID, uuid) => {
    const token = this.props.token;
    const headers = {
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
        Authorization: `${token.token_type} ${token.access_token}`,
      },
    };
    const invites = this.state.invites !== 0 ? this.state.invites - 1 : 0;
    const nid = this.props.currentOrg.nid;
    this.setState({
      invites,
      loadingContact: {
        loading: true,
        buttonID: candidateID,
      },
    });
    axios
      .post(`${oauthConfig.baseUrl}/api/organization/${nid}/request_access/candidate/${candidateID}?_format=json`, [], headers)
      .then((res) => {
        if (res.data.message.includes("gained")) {
          gainedCandidates.push(candidateID);
        }
        if (res.data.message.includes("received an email")) {
          requestedCandidates.push(candidateID);
        }
        temporaryUuid = uuid;
        this.setState({
          dialog: {
            openDialog: true,
            dialogMsg: res.data.message,
          },
          loadingContact: {
            loading: false,
            buttonID: null,
          },
        });
      })
      .catch((err) => {
        const error = { ...err };
        this.setState({
          dialog: {
            openDialog: true,
            dialogMsg: error.response.data.message ? error.response.data.message : "Something went wrong!",
            error: true,
          },
          loadingContact: {
            loading: false,
            buttonID: null,
          },
        });
      });
  };

  handleGoToProfile = (uuid) => {
    //Href option should be added for desktop
    // return `/candidates/filter/${uuid || temporaryUuid}?${this.state.query}&page=${this.state.page}`
    this.props.history.push({
      pathname: `/candidates/filter/${uuid || temporaryUuid}`,
      search: `${this.state.query}&page=${this.state.page}`,
    });
  };

  handleSearch = async (query, page, isAllFilter) => {
    this.setState({
      loading: true,
      candidates: null,
    });
    let queryMerge = "";
    if (this.state.query && !isAllFilter) {
      // console.log(query)
      let newQuery = query.split("&").slice(1);
      // console.log(newQuery)
      let newQueryProp = newQuery[0].split("=");
      // console.log(newQueryProp)
      let exist = false;
      let prevQuery = this.state.query.split("&").slice(1);
      let prevQueryProps = prevQuery.map((el) => {
        return el.split("=");
      });
      // check if filter exists
      prevQueryProps.forEach((el) => {
        if (el[0] === newQueryProp[0]) {
          exist = true;
        }
      });
      // change filter values if filter exists
      if (exist) {
        let replaceQuery = prevQueryProps.map((el) => {
          if (el[0] === newQueryProp[0]) {
            el[1] = newQueryProp[1];
            if (!newQueryProp[1]) {
              el[0] = null;
              el[1] = null;
            }
          }
          return el.join("=");
        });
        const filtered = replaceQuery.filter((el) => el != "=");
        if (filtered.length > 0) {
          queryMerge = `&${filtered.join("&")}`;
        } else {
          queryMerge = "";
        }
      } else {
        // overight status filter if status exists
        if (query.includes("connection_status")) {
          const nid = this.props.currentOrg.nid;
          let prevQuery = this.state.query;
          let clearedQuery = prevQuery
            .replace(`&connection_status_rejected_negate=${nid}&connection_status_requested_negate=${nid}&connection_status_connected_negate=${nid}`, ``)
            .replace(`&connection_status_requested=${nid}`, ``)
            .replace(`&connection_status_connected=${nid}`, ``);
          if (query === "&connection_status_all=") {
            queryMerge = clearedQuery;
          } else {
            queryMerge = clearedQuery + query;
          }
        } else {
          const availableValues = query.split("=")[1];
          const noValues = availableValues.length === 0;
          queryMerge = noValues ? this.state.query : this.state.query + query;
        }
      }
    } else {
      if (query) {
        const availableValues = query.split("=")[1];
        const noValues = availableValues.length === 0;
        queryMerge = noValues ? "" : query;
      }
    }

    localStorage.setItem("candidateFilterQuery", `?page=${page}&q=1${queryMerge}`);
    let token = this.props.token;

    let headers = {
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
        Authorization: `${token.token_type} ${token.access_token}`,
      },
    };
    const nid = this.props.currentOrg.nid;
    const candidates = await axios.get(`${oauthConfig.baseUrl}/api/candidates/${this.props.candidateDB ? "export" : "demo_export"}?_format=json&page=${page}&org_id=${nid}${queryMerge}`, headers);

    this.props.history.push({
      search: `?page=${page}&q=1${queryMerge}`,
    });
    if (candidates && candidates.data.length > 0) {
      totalCandidates = candidates.data[0].total_rows;
      pageCount = Math.ceil(totalCandidates / perPage);
    }
    if (candidates) {
      this.setState({
        loading: false,
        candidates: candidates.data,
        query: queryMerge,
        pageCount,
        totalCandidates,
        page,
      });
    }
  };

  handleSubscribe = () => {
    this.setState({
      dialog: {
        openDialog: true,
        subscribe: true,
        dialogMsg: "To enable access to candidate profiles upgrade your account.  Please purchase a network partnership package or contact our sales team.",
      },
    });
  };

  getInvites = async () => {
    let token = this.props.token;
    let headers = {
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
        Authorization: `${token.token_type} ${token.access_token}`,
      },
    };

    const nid = this.props.currentOrg.nid;
    const invites = await axios.get(`${oauthConfig.baseUrl}/api/organization/${nid}/invite_quota_left`, headers);
    this.setState({ invites: invites.data.invite_quota_allowed - invites.data.invite_quota_used });
  };

  changeInvites = () => {};

  render() {
    const { classes, candidateDB, ...other } = this.props;
    let candidates;
    if (this.state.candidates && this.state.candidates.length > 0) {
      candidates = this.state.candidates.map((el) => {
        return (
          <Candidate
            key={el.node_id}
            id={el.node_id}
            orgId={this.props.currentOrg.nid}
            uuid={el.node_uuid}
            city={el.field_city_text}
            country={el.field_candidate_country}
            role={el.field_current_role}
            first={el.field_first_name}
            last={el.field_last_name}
            experience={el.field_professional_experience}
            picture={el.field_profile_picture}
            skills={Boolean(el.field_technical_skills_name) ? el.field_technical_skills_name.split(";") : []}
            status={el.connection_status}
            candidateDB={candidateDB}
            created={el.created}
            loadingContact={this.state.loadingContact}
            handleContact={this.handleContact}
            gained={gainedCandidates}
            requested={requestedCandidates}
            invites={this.state.invites}
            handleGoToProfile={this.handleGoToProfile}
            handleClose={this.handleClose}
            handleSubscribe={this.handleSubscribe}
          />
        );
      });
    }

    if (this.state.loading) {
      candidates = <Loader />;
    }

    return (
      <Fragment>
        <div className={classes.content}>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                className={classes.search}
                fullWidth={true}
                variant="outlined"
                onChange={this.handleChange}
                value={this.state.search}
                onKeyPress={this.handleKeyPress}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box color="#757575" display="flex">
                        <SearchIcon fontSize="small" />
                        <Box fontSize={12}>Search</Box>
                      </Box>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button type="submit" variant="contained" fullWidth disabled={!this.state.search} onClick={() => this.handleSearch(`&search=${this.state.search.replace(/\s+/g, "+")}`, 0)}>
                        Search
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <EmployerCandidateFilter
              {...other}
              query={this.state.query}
              page={this.state.page}
              predefinedVals={values}
              currentOrgId={this.props.currentOrg.nid}
              candidates={this.state.candidates}
              totalCandidates={this.state.totalCandidates}
              handleReset={this.handleReset}
              handleSearch={this.handleSearch}
            />
          </Grid>

          {candidates}
          {this.state.pageCount && (
            <ReactPaginate
              previousLabel={<NavigateBeforeIcon />}
              nextLabel={<NavigateNextIcon />}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              initialPage={this.state.page}
              marginPagesDisplayed={1}
              pageRangeDisplayed={4}
              disableInitialCallback={true}
              onPageChange={this.handlePageClick}
              containerClassName={classes.pagination}
              activeClassName={classes.active}
            />
          )}
        </div>

        <Dialog open={this.state.dialog.openDialog} TransitionComponent={Transition}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>{this.state.dialog.error ? <ErrorText>{parse(this.state.dialog.dialogMsg)}</ErrorText> : <Typography>{parse(this.state.dialog.dialogMsg)}</Typography>}</DialogContent>
          <DialogActions>
            {this.state.dialog.dialogMsg.includes("gained") && (
              <Button type="submit" variant="contained" href={this.handleGoToProfile(null)}>
                Profile
              </Button>
            )}

            {this.state.dialog.subscribe && (
              <Fragment>
                <Button target="_blank" rel="noopener" href="https://www.womentech.net/women-tech-conference/partner?product=NetworkHiring" color="primary" variant="contained">
                  Contact Sales
                </Button>
                <Button target="_blank" rel="noopener" href="https://shop.womentech.net/products/network-membership" color="primary" variant="contained">
                  Upgrade
                </Button>
              </Fragment>
            )}
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Candidates);
