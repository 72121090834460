import React, { useState, Fragment } from "react";
import { makeStyles, Grid, Button, Avatar, Typography, CircularProgress, Paper, Tooltip } from "@material-ui/core";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import oauthConfig from "../../oauthConfig";
import PersonIcon from "@material-ui/icons/Person";
import { Html5Entities } from "html-entities";
import Blur from "react-blur";

const htmlEntities = new Html5Entities();

const useStyles = makeStyles((theme) => ({
  root: {
    // background: '#ffffff',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    margin: `${theme.spacing(1)}px 0`,
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  skill: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    background: theme.palette.grey[400],
    color: "#fff",
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0`,
  },
  contactBtn: {
    width: "94px",
    height: "34px",
  },
  button: {
    marginBottom: `${theme.spacing(1)}px`,
  },
  new: {
    position: "absolute",
  },
}));

const Candidate = (props) => {
  const classes = useStyles();
  const [toggled, setToggled] = useState(false);
  const skillsFirst = props.skills.slice(0, 4);
  const skillsLast = props.skills.slice(4, props.skills.length);
  const skillsLeft = skillsLast.length;
  let btnText = "";
  let btnDisabled = false;
  const geined = props.gained.length > 0;
  const requested = props.requested.length > 0;
  const instantStatus = geined || requested;
  let matchIdGained;
  let matchIdRequested;

  //Time for created value
  const now = new Date();
  const nowMonth = now.getMonth();
  const nowYear = now.getFullYear();
  const createdSeconds = new Date(props.created * 1000);
  const createdMonth = createdSeconds.getMonth();
  const createdYear = createdSeconds.getFullYear();
  const isNew = nowYear === createdYear && nowMonth === createdMonth;

  if (instantStatus) {
    matchIdGained = props.gained.indexOf(props.id) !== -1;
    matchIdRequested = props.requested.indexOf(props.id) !== -1;
  }

  if (props.status === "requested" || matchIdRequested) {
    btnText = "Contacted";
    btnDisabled = true;
  } else if (props.status === "rejected") {
    btnText = "Declined";
    btnDisabled = true;
  } else if (props.status === "connected" || matchIdGained) {
    btnText = "View Profile";
  } else if (props.status === "new") {
    btnText = "Get Intro";
  }

  // Conditions of candidate DB access
  let actionButtons;
  let profilePicture;
  const getIntroBtn = () => {
    const btn = btnDisabled ? (
      <Button data-company-id={1} fullWidth type="submit" variant="contained" disabled={btnDisabled} onClick={() => props.handleContact(props.id, props.uuid)}>
        {props.loadingContact.buttonID == props.id && props.loadingContact.loading ? <CircularProgress color="secondary" size={20} /> : btnText}
      </Button>
    ) : (
      <a style={{ width: "100%" }} href="javascript:;" class="connection-request" data-company-id={props.orgId}>
        <Tooltip title={`${props.invites} invites left this week`}>
          <Button fullWidth type="submit" variant="contained" onClick={() => props.handleContact(props.id, props.uuid)}>
            {props.loadingContact.buttonID == props.id && props.loadingContact.loading ? <CircularProgress color="secondary" size={20} /> : btnText}
          </Button>
        </Tooltip>
      </a>
    );
    return btn;
  };

  if (props.candidateDB) {
    actionButtons =
      props.status === "connected" || matchIdGained ? (
        <Button fullWidth type="submit" variant="contained" onClick={() => props.handleGoToProfile(props.uuid)}>
          View Profile
        </Button>
      ) : (
        getIntroBtn()
      );

    profilePicture = <Avatar className={classes.large} src={`${oauthConfig.baseUrl}/${props.picture}`} />;
  } else {
    actionButtons = (
      <Fragment>
        <Button fullWidth type="submit" variant="contained" className={classes.button} onClick={() => props.handleGoToProfile(props.uuid)}>
          View Profile
        </Button>
        <Button fullWidth type="submit" variant="contained" onClick={() => props.handleSubscribe()}>
          Get Intro
        </Button>
      </Fragment>
    );

    profilePicture = (
      <Avatar className={classes.large}>
        <Blur blurRadius={10} img={props.picture} className={classes.large} />
      </Avatar>
    );
  }
  return (
    <Fragment>
      <Paper>
        {isNew && <FiberNewIcon color="primary" className={classes.new} />}
        <Grid container alignItems="center" className={classes.root}>
          <Grid item container justify="center" xs={12} md={2}>
            {props.picture.length > 0 ? (
              profilePicture
            ) : (
              <Avatar className={classes.large}>
                <PersonIcon style={{ fontSize: 50 }} />
              </Avatar>
            )}
          </Grid>
          <Grid item container xs={12} md={8}>
            <Grid item container direction="column" spacing={1}>
              <Grid item container alignItems="flex-start" spacing={1}>
                <Grid item xs={12} sm={5}>
                  <Typography variant="h6">
                    {htmlEntities.decode(props.first)} {htmlEntities.decode(props.last)}
                  </Typography>
                  <Typography variant="body2">{htmlEntities.decode(props.role)}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="h6">Location:</Typography>
                  <Typography variant="body2">
                    {htmlEntities.decode(props.country)}
                    {props.country && props.city ? ", " : null}
                    {props.city}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  {props.experience && (
                    <Fragment>
                      <Typography variant="h6">Experience:</Typography>
                      <Typography variant="body2">{props.experience} years</Typography>
                    </Fragment>
                  )}
                </Grid>
              </Grid>
              <Grid item container>
                {skillsFirst.map((el) => (
                  <div className={classes.skill} key={el}>
                    {el}
                  </div>
                ))}
                {toggled
                  ? skillsLast.map((el) => (
                      <div className={classes.skill} key={el}>
                        {el}
                      </div>
                    ))
                  : null}
                {props.skills.length > 4 ? (
                  <span className={classes.skill} style={{ cursor: "pointer" }} onClick={() => setToggled((toggled) => !toggled)}>
                    {!toggled ? "+" : "-"}
                    {skillsLeft}
                  </span>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justify="center" xs={12} md={2}>
            {actionButtons}
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};

export default Candidate;
